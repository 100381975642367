import React, { useState } from 'react';
import Link from 'next/link';
import firebase from 'firebase/compat/app';
import { useDispatch } from 'react-redux';

import { login } from 'redux/auth/actions';

import Button from 'components/uielements/button';
import SignInStyleWrapper from '../styled/SignIn.styles';

import Api from 'library/api';
import BrightLogo from 'assets/images/logo/bright_white.svg';
import { setCookie, removeCookie } from 'library/helpers/session';

/**
 * Sign in page
 *
 * @return  {JSX.Element}
 */
export default function SignInPage() {
  const dispatch = useDispatch();

  const [loginError, setLoginError] = useState('');
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);

  const signInWithGoogle = async () => {
    try {
      setLoginError('');
      setIsLoadingGoogle(true);

      const provider = new firebase.auth.GoogleAuthProvider();
      const result = await firebase.auth().signInWithPopup(provider);
      const idToken = await result.user.getIdToken();
      await Promise.all([
        // temporary set token for admin check
        setCookie('token', idToken),
        // Check to see if admin exists in admin collection
        Api.get(`/admins/${result.user.uid}`).execute(idToken),
        // login
        dispatch(login(idToken)),
      ]);
      setIsLoadingGoogle(false);
    } catch (error) {
      setIsLoadingGoogle(false);
      removeCookie('token');
      setLoginError(error.message ?? 'Something went wrong!');
    }
  };

  const getLoginForm = () => {
    return (
      <div className='isoSignInForm'>
        <div className='isoInputWrapper'>
          <Button
            type='primary'
            onClick={signInWithGoogle}
            loading={isLoadingGoogle}
          >
            Sign in with Google
          </Button>
        </div>
      </div>
    );
  };

  return (
    <SignInStyleWrapper className='isoSignInPage' data-rtl>
      <div className='isoLoginContentWrapper'>
        <div className='isoLoginContent'>
          <div className='isoLogoWrapper'>
            <Link href='/dashboard'>
              <img src={BrightLogo} style={ { width: '100%', height: 'auto' } }/>
            </Link>
          </div>

          {getLoginForm()}

          {loginError ? (
            <div className='isoSignInError'>{loginError}</div>
          ) : null}
        </div>
      </div>
    </SignInStyleWrapper>
  );
}
