import Router from 'next/router';
import Api from '../../library/api';
import types from './types';
import firebase from 'firebase/compat/app';
import { removeCookie, setCookie } from 'library/helpers/session';

export const login = (idToken) => ({
  type: types.LOGIN,
  promise: new Promise(async (resolve, reject) => {
    try {
      setCookie('token', idToken);
      // Router the user to the dashboard
      Router.replace('/dashboard');
      resolve({
        idToken,
      });
    } catch (error) {
      reject(error);
    }
  }),
});

export const logout = () => ({
  type: types.LOGOUT,
  promise: firebase
      .auth()
      .signOut()
      .then(() => {
        removeCookie('token');
        // Router the user to login
        Router.replace('/');
      }),
});

export const getCurrentAdmin = () => ({
  type: types.GET_CURRENT_ADMIN,
  promise: Api.get(`/users/current`),
});


export const editAdmin = (data) => ({
  type: types.EDIT_ADMIN,
  promise: Api.patch(`/admins/${data.id}`, data),
});

export const deleteAdmin = (data) => ({
  type: types.DELETE_ADMIN,
  promise: Api.delete(`/admins/${data}`),
});

export const setAuthToken = (idToken) => ({
  type: types.LOGIN,
  idToken,
});
