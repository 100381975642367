import fetch from 'node-fetch';
import { getCookie, setCookie } from 'library/helpers/session';
import firebase from 'firebase/compat/app';

const defaultHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};
const isServer = typeof window === 'undefined';

/**
 * Auth wrapper class
 */
export class AuthWrapper {
  /**
   * Construct auth wrapper class
   *
   * @param   {Promise}  promise  promise to execute
   *
   */
  constructor(promise) {
    this.promise = promise;
  }
  /**
   * Execute an authenticated request
   *
   * @param   {string}  authToken  auth token
   *
   * @return  {Promise}            Promise with auth token added
   */
  async execute(authToken = '') {
    if (!authToken) {
      authToken = getCookie('token');
    }
    return await this.promise(authToken);
  }
  /**
   * Execute an authenticated request
   *
   * @param   {object}  state      Full state object
   *
   * @return  {Promise}            Promise with auth token added
   */
  async executeState(state = {}) {
    return await this.execute(state?.Auth?.auth?.idToken);
  }
}
/**
 * API
 */
class Api {
  /**
   * [headers description]
   *
   * @return  {[type]}  [return description]
   */
  static headers() {
    return Object.assign({}, defaultHeaders,
        ({ 'X-API-KEY': process.env.NEXT_PUBLIC_AUTHORIZATION }));
  }
  /**
   * Execute GET Request
   *
   * @param   {string}  route   Api route
   * @param   {object}  params  Request parameters
   *
   * @return  {AuthWrapper}     AuthWrapper for http request
   */
  static get(route, params) {
    return this.xhr(route, params, 'GET');
  }

  /**
   * Execute PATCH Request
   *
   * @param   {string}  route   Api route
   * @param   {object}  params  Request parameters
   *
   * @return  {AuthWrapper}     AuthWrapper for http request
   */
  static patch(route, params) {
    return this.xhr(route, params, 'PATCH');
  }

  /**
   * Execute PUT Request
   *
   * @param   {string}  route   Api route
   * @param   {object}  params  Request parameters
   *
   * @return  {AuthWrapper}     AuthWrapper for http request
   */
  static put(route, params) {
    return this.xhr(route, params, 'PUT');
  }

  /**
   * Execute POST Request
   *
   * @param   {string}  route   Api route
   * @param   {object}  params  Request parameters
   *
   * @return  {AuthWrapper}     AuthWrapper for http request
   */
  static post(route, params) {
    return this.xhr(route, params, 'POST');
  }

  /**
   * Execute DELETE Request
   * @param   {string}  route   Api route
   * @param   {object}  params  Request parameters
   *
   * @return  {AuthWrapper}     AuthWrapper for http request
   */
  static delete(route, params) {
    return this.xhr(route, params, 'DELETE');
  }

  /**
   * postMultipart request
   *
   * @param   {string}  route   Api route
   * @param   {object}  params  Request parameters
   *
   * @return  {AuthWrapper}     AuthWrapper for http request
   */
  static postMultipart(route, params) {
    return this.xhrMulti(route, params);
  }

  /**
   * postFormData request
   *
   * @param   {string}  route   Api route
   * @param   {object}  formData  Request parameters
   *
   * @return  {AuthWrapper}     AuthWrapper for http request
   */
  static postFormData(route, formData) {
    return this.xhrFormData(route, formData);
  }

  /**
   * Execute XHR request
   *
   * @param   {string}  route   Api route
   * @param   {object}  params  Request parameters
   * @param   {string}  verb    HTTP verb
   *
   * @return  {AuthWrapper}     AuthWrapper for http request
   */
  static xhr(route, params, verb) {
    return new AuthWrapper(async (authToken) => {
      const options = Object.assign({ method: verb });
      options.headers = this.headers();
      options.headers['Authorization'] = `Bearer ${authToken}`;
      params = params ? params : {};

      let query; let url;
      if (verb === 'GET') {
        query = this.getQuery(params);
        (!query) ? url = `${process.env.NEXT_PUBLIC_HOST_URL}${route}` : url = `${process.env.NEXT_PUBLIC_HOST_URL}${route}?${query}`;
      } else {
        options.body = JSON.stringify(params);
        url = `${process.env.NEXT_PUBLIC_HOST_URL}${route}`;
      }

      let response = await fetch(url, options);
      let data = await response.json();

      if (
        !isServer &&
        options.headers['Authorization'] &&
        response.status === 401) {
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
          authToken = await currentUser.getIdToken();
          setCookie('token', authToken);
          options.headers['Authorization'] = `Bearer ${authToken}`;
          response = await fetch(url, options);
          data = await response.json();
        }
      }

      if (!response.ok) throw data;

      return data;
    });
  }

  /**
   * xhr multi request
   *
   * @param   {string}  route   Api route
   * @param   {object}  params  Request parameters
   *
   * @return  {AuthWrapper}     AuthWrapper for http request
   */
  static async xhrMulti(route, params) {
    return new AuthWrapper(async (authToken) => {
      const options = {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      };

      options.body = new FormData();

      for (const key in params) {
        if (key === 'files') {
          params[key].forEach((file, i) => {
            options.body.append(`file[${i}]`, file);
          });
        } else {
          options.body.append(key, params[key]);
        }
      }
      const url = `${process.env.NEXT_PUBLIC_HOST_URL}${route}`;

      return fetch(url, options)
          .then((resp) => {
            const json = resp.json();
            if (resp.ok) {
              return json;
            }
            return json.then((err) => {
              throw err;
            });
          })
          .then((json) => {
            return json;
          });
    });
  }
  /**
   * xhrFormData request
   *
   * @param   {string}  route   Api route
   * @param   {object}  formData    form data
   *
   * @return  {AuthWrapper}     AuthWrapper for http request
   */
  static async xhrFormData(route, formData) {
    return new AuthWrapper(async (authToken) => {
      const options = {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      };

      const url = `${process.env.NEXT_PUBLIC_HOST_URL}${route}`;

      return fetch(url, options)
          .then((resp) => {
            const json = resp.json();
            if (resp.ok) {
              return json;
            }
            return json.then((err) => {
              throw err;
            });
          })
          .then((json) => {
            return json;
          });
    });
  }

  /**
   * Get query params
   *
   * @param   {object}  params  params
   *
   * @return  {string}          Query string
   */
  static getQuery(params) {
    return Object.keys(params)
        .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
  }
}

export default Api;
